import React, { useContext, useEffect, useState } from "react"
import * as s from "./index.module.less"
import { Button, Col, Input, Label, Row } from "reactstrap"
import { SpaceContext } from "@hooks/SpaceContext"
import ToggleSwitch from "../../ToggleSwitch"
import DatePicker from "../../DatePicker"
import CounterInput from "../../CounterInput"
import StyledInput from "../../Input"
import PriceField from "../../PriceField"
import Assets from "./Assets"
import { v4 as uuid } from "uuid"
import TeamDetails from "./TeamDetails"
import { useAuth } from "@auth/useAuth"
import { parse } from "date-fns"
import get from "lodash/get"
import userHasAccess from "../../../../../utils/userHasAccess"

const OFFICE = "office"
const DEDICATED_DESK = "dd"

const OfficeDeskDetails = ({
  isOpen = false,
  toggle = () => null,
  data = {},
  refresh = () => null,
  floorId = null,
  svgId = null,
  slugForProfileGenerator = null,
  space_id = null,
}) => {
  const { makeRequest } = useAuth()

  const {
    data: { soData },
  } = useContext(SpaceContext)

  const [type, setType] = useState("")
  const [id, setId] = useState("")
  const [saving, setSaving] = useState(false)
  // Office
  const [price, setPrice] = useState(0)
  const [pricePerDay, setPricePerDay] = useState(0)
  const [pricePerHour, setPricePerHour] = useState(0)
  const [capacity, setCapacity] = useState(0)
  const [officeSqFt, setOfficeSqFt] = useState(0)
  const [label, setLabel] = useState("")
  const [spaceAvailable, setSpaceAvailable] = useState(true)
  const [spaceAvailableFrom, setSpaceAvailableFrom] = useState(null)

  const [occupant, setOccupant] = useState({})

  const images = data.images || []
  const pdf = data.asset_url || null

  const resetSpace = async () => {
    if (!data.id) {
      clearData()
      toggle()
      return
    }
    // setSaving(true)
    const table_type = type === DEDICATED_DESK ? "dedicated_desks" : "offices"

    try {
      const response = await makeRequest(
        "sp/delete-space-details/resetSpaceBlockDetails",
        {
          id: data.id,
          type: table_type,
        }
      )
      setSaving(false)
      if (response.message === "Success") {
        toggle()
        refresh()
      } else {
        alert("There was an error. Could not save.")
      }
    } catch (e) {
      setSaving(false)
      window.alert("There was a backend error.")
    }
  }

  const add = async () => {
    let updatedData = {}
    let endpoint = ""
    if (type === DEDICATED_DESK) {
      updatedData = {
        id,
        floorId,
        space_id,
        occupant,
        pricePerDesk: price,
        desksAvailable: capacity,
        svgId,
        spaceAvailable,
        spaceAvailableFrom,
        minimumDesks: 0,
        officeLabel: label,
      }
      endpoint = "sp/dedicatedDesks"
    } else if (type === OFFICE) {
      updatedData = {
        id,
        space_id,
        floorId,
        occupant,
        officePrice: price,
        officeCapacity: capacity,
        officeSqFt,
        svgId,
        spaceAvailable,
        spaceAvailableFrom,
        pricePerDay,
        pricePerHour,
        officeLabel: label,
      }
      endpoint = "sp/offices"
    }
    setSaving(true)
    try {
      const response = await makeRequest(endpoint, updatedData)
      setSaving(false)
      if (response.message === "Success") {
        toggle()
        refresh()
      } else {
        alert("There was an error. Could not save.")
      }
    } catch (e) {
      setSaving(false)
      window.alert("There was a backend error.")
    }
  }

  useEffect(() => {
    if (!isOpen) {
      clearData()
      return
    }

    setId(data.id || uuid())

    if (!data) {
      return
    }

    let ed = get(data, "occupant.contract_expiry_date", null)

    ed = ed ? ed.split("T")[0] : null

    const contract_expiry_date = ed ? parse(ed, "yyyy-MM-dd", new Date()) : null

    setType(data.type || "")
    setSpaceAvailable(data.space_available)
    setSpaceAvailableFrom(
      data.space_available_from ? new Date(data.space_available_from) : null
    )

    setPrice(data.price || data.price_per_desk || 0)
    setCapacity(data.max_capacity || data.desks_available || 0)
    setLabel(data.label || "")
    setOfficeSqFt(data.square_footage || 0)
    setPricePerDay(data.price_per_day || 0)
    setPricePerHour(data.price_per_hour || 0)
    setOccupant(
      data.occupant
        ? {
            ...data.occupant,
            contract_expiry_date,
            id: null,
          }
        : { id: null }
    )
  }, [isOpen, data])

  const clearData = () => {
    setId("")
    setPrice(0)
    setCapacity(0)
    setOfficeSqFt(0)
    setLabel("")
    setSpaceAvailable(true)
    setSpaceAvailableFrom(null)
    setPricePerDay(0)
    setPricePerHour(0)
    setType("")
    setOccupant({})
  }

  const updateOccupant = (id, val) => {
    setOccupant({
      ...occupant,
      [id]: val,
    })
  }

  return (
    <div className={`${s.wrapper} ${isOpen ? s.open : ""}`} onClick={toggle}>
      <div className={s.inner} onClick={(e) => e.stopPropagation()}>
        <div className={s.content}>
          <div>
            <Row>
              <Col xs="12">
                <div className="d-flex justify-content-start align-items-center mb-3">
                  <h5 className={"my-0"}>Type of space</h5>
                  {data.id && soData.profile_generator_url && (
                    <a
                      href={`${soData.profile_generator_url}/${slugForProfileGenerator}?id=${data.id}`}
                      target={"_blank"}
                      rel={"noreferrer"}
                      className={`btn btn-link ml-auto px-0 ${s.profile_generator}`}
                    >
                      Generate Profile
                    </a>
                  )}
                </div>
              </Col>
              <Col xs="12">
                <Label
                  check
                  className={type === DEDICATED_DESK ? "checkbox-disabled" : ""}
                >
                  <Input
                    type="radio"
                    name={"office"}
                    disabled={type === DEDICATED_DESK}
                    checked={type === OFFICE}
                    onChange={() => setType("office")}
                  />{" "}
                  <span>Office</span>
                </Label>
              </Col>
              <Col xs="12" className={"mt-2"}>
                <Label
                  check
                  className={
                    type === OFFICE ||
                    !userHasAccess(soData, "monthly_offices_enabled")
                      ? "checkbox-disabled"
                      : ""
                  }
                >
                  <Input
                    type="radio"
                    name={"dd"}
                    disabled={
                      type === OFFICE ||
                      !userHasAccess(soData, "monthly_offices_enabled")
                    }
                    checked={type === DEDICATED_DESK}
                    onChange={() => setType("dd")}
                  />{" "}
                  <span>Dedicated desks</span>
                </Label>
              </Col>
            </Row>

            {type && (
              <>
                <Row>
                  <Col>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} className={"d-flex align-items-center"}>
                    <ToggleSwitch
                      name="availability"
                      label="Available?"
                      value={spaceAvailable}
                      onChange={setSpaceAvailable}
                      inline
                    />
                  </Col>
                  <Col sm={7} className={"d-flex align-items-center"}>
                    {!spaceAvailable && (
                      <DatePicker
                        withWeekends
                        label="Available date"
                        value={spaceAvailableFrom}
                        onChange={setSpaceAvailableFrom}
                        inline
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col sm={type === DEDICATED_DESK ? 3 : 4}>
                    <CounterInput
                      label={
                        type === DEDICATED_DESK
                          ? "Available desks"
                          : "Max Capacity"
                      }
                      name="capacity"
                      min={0}
                      max={1000}
                      value={capacity}
                      onChange={setCapacity}
                      fullWidth
                    />
                  </Col>
                  {type === OFFICE && (
                    <Col sm={4}>
                      <StyledInput
                        label="Sq Ft"
                        name="officeCapacity"
                        min={0}
                        max={100000}
                        value={officeSqFt}
                        onChange={setOfficeSqFt}
                        type="number"
                      />
                    </Col>
                  )}
                  <Col sm={4}>
                    <StyledInput
                      label={`${
                        type === DEDICATED_DESK ? "Desk" : "Office"
                      } ID`}
                      name="ID"
                      value={label}
                      onChange={setLabel}
                      type="text"
                    />
                  </Col>
                  {userHasAccess(soData, "monthly_offices_enabled") && (
                    <Col sm={4}>
                      <PriceField
                        label={`Price${
                          type === DEDICATED_DESK ? " per desk" : ""
                        } per month`}
                        name="officePrice"
                        value={price}
                        onChange={setPrice}
                      />
                    </Col>
                  )}
                  {type === OFFICE && (
                    <>
                      {userHasAccess(
                        soData,
                        "on_demand_offices_daily_enabled"
                      ) && (
                        <Col sm={4}>
                          <PriceField
                            label="Price per day"
                            name="pricePerDay"
                            value={pricePerDay}
                            onChange={setPricePerDay}
                          />
                        </Col>
                      )}
                      {userHasAccess(
                        soData,
                        "on_demand_offices_hourly_enabled"
                      ) && (
                        <Col sm={4}>
                          <PriceField
                            label="Price per hour"
                            name="pricePerHour"
                            value={pricePerHour}
                            onChange={setPricePerHour}
                          />
                        </Col>
                      )}
                    </>
                  )}
                </Row>
                <hr />
                {type === OFFICE && (
                  <>
                    <TeamDetails
                      occupant={occupant}
                      updateOccupant={updateOccupant}
                    />
                    <hr />
                  </>
                )}
                <Assets id={id} images={images} pdf={pdf} />
                <hr />
              </>
            )}
          </div>
          <div className={"d-sm-flex align-items-center mt-auto"}>
            <Button
              size={"sm"}
              color="primary"
              disabled={saving}
              className="btn-square"
              onClick={add}
            >
              Save
            </Button>
            <Button
              color="muted"
              outline
              size={"sm"}
              onClick={toggle}
              className="btn-square ml-3 mr-auto"
            >
              Close
            </Button>
            <br className={"d-sm-none"} />
            <Button
              color="link"
              outline
              size={"sm"}
              onClick={() => resetSpace()}
              disabled={saving}
              className="btn-square ml-sm-auto text-danger mt-5 mt-sm-0"
            >
              Reset all fields
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfficeDeskDetails
